<template>
	<div class="category-selection">
		<div
			v-for="{ title, fontType } in TYPOGRAPHY_CATEGORIES"
			:key="title"
			@click="goToTypographyTypeSelect(title)"
		>
			<div class="category">
				<span class="category__title z-body-small z-body-small--strong">{{ title }}</span>
				<div>
					<span
						class="category__font-name z-body-small z-body-small--strong"
					>
						{{ getFontNames[fontType] }}
					</span>
					<ZyroButton
						v-qa="`builder-sidemenu-globalstyles-font-${title}`"
						class="category__icon"
						icon="chevron-right"
						icon-dimensions="8px"
					/>
				</div>
			</div>
			<ZyroSeparator />
		</div>
		<ZyroButton
			v-qa="'userstyles-typography-resettextstyles-openmodal-btn'"
			theme="primary"
			icon-left="refresh-icon"
			color="white"
			class="category-selection__restore-button"
			@click="openModal({ name: USER_TYPOGRAPHY_STYLES_RESET_MODAL })"
		>
			{{ $t('builder.userStyles.typography.resetBtn') }}
		</ZyroButton>
	</div>
</template>

<script>
import {
	mapGetters,
	mapMutations,
} from 'vuex';

import { useTypographyStylesLibrary } from '@/components/builder-drawers/drawers/partials/stylesDrawer/typograpghy/use/useTypographyStylesLibrary';
import {
	PROPERTY_FONT_PRIMARY,
	PROPERTY_FONT_SECONDARY,
} from '@/constants/globalStyles';
import i18n from '@/i18n/setup';
import {
	USER_STYLES_HEADING_SELECT_KEY,
	USER_STYLES_PARAGRAPH_SELECT_KEY,
} from '@/store/builder/constants/drawerPages';
import { USER_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import { USER_TYPOGRAPHY_STYLES_RESET_MODAL } from '@/store/builder/constants/modals';
import {
	mapActionsGui,
	CHANGE_DRAWER_PAGE,
	OPEN_MODAL,
} from '@/store/builder/gui';

const TITLE_HEADINGS = i18n.t('builder.userStyles.typography.headings');
const TITLE_PARAGRAPHS = i18n.t('builder.userStyles.typography.paragraphs');

const TYPOGRAPHY_CATEGORIES = [
	{
		title: TITLE_HEADINGS,
		fontType: PROPERTY_FONT_PRIMARY,
	},
	{
		title: TITLE_PARAGRAPHS,
		fontType: PROPERTY_FONT_SECONDARY,
	},
];
const PAGE_KEY_MAP = {
	[TITLE_HEADINGS]: USER_STYLES_HEADING_SELECT_KEY,
	[TITLE_PARAGRAPHS]: USER_STYLES_PARAGRAPH_SELECT_KEY,
};

export default {
	setup() {
		const { currentTypographyStyleLibrary } = useTypographyStylesLibrary();

		return {
			currentTypographyStyleLibrary,
			TYPOGRAPHY_CATEGORIES,
			USER_TYPOGRAPHY_STYLES_RESET_MODAL,
		};
	},
	computed: { ...mapGetters('fonts', ['getFontNames']) },
	methods: {
		...mapMutations(['setStyleProperties']),
		...mapActionsGui({
			openModal: OPEN_MODAL,
			changeDrawerPage: CHANGE_DRAWER_PAGE,
		}),
		goToTypographyTypeSelect(type) {
			this.changeDrawerPage({
				drawerKey: USER_STYLES_DRAWER,
				pageKey: PAGE_KEY_MAP[type],
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import '@/components/builder-drawers/drawers/partials/stylesDrawer/UserStyles.scss';

.category {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px 0;
	cursor: pointer;

	&__font-name {
		color: $grey-600;
	}

	&__icon {
		margin-left: 36px;
	}
}
</style>
